import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const Setting = Loadable(lazy(() => import('app/views/settings/Setting')));

const settingRoutes = [
  {
    path: '/settings',
    element: <Setting/>,
    auth: authRoles.admin
  }
];

export default settingRoutes;

