import { combineReducers } from 'redux';
import { AdminReducer } from './admin/admin.reducer';
import { TitleReducer } from './title/title.reducer';
import { ScreenReducer } from './screen/screen.reducer';
import { SettingReducer } from './setting/setting.reducer';

// reducer import

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  admin: AdminReducer,
  title: TitleReducer,
  screen: ScreenReducer,
  setting: SettingReducer
});

export default reducer;
