import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import adminRoutes from './views/admins/AdminRoutes';
import titleRoutes from './views/titles/TitleRoutes';
import screenRoutes from './views/screens/ScreenRoutes';
import settingRoutes from './views/settings/SettingRoutes';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));

// dashboard page

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // dashboard route
      {
        path: '/dashboard/default',
        element: <h1>sadas</h1>,
        auth: authRoles.admin
      },
      ...adminRoutes,
      ...titleRoutes,
      ...screenRoutes,
      ...settingRoutes
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },

  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
