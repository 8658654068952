import SCREEN_ACTION_TYPES from './screen.types';

export const SCREEN_INITIAL_STATE = {
  screenLists: [],
};

export const ScreenReducer = (state = SCREEN_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SCREEN_ACTION_TYPES.LOAD_SCREENS: {
      return { ...state, screenLists: payload };
    }
    default:
      return state;
  }
};
