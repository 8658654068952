import TITLE_ACTION_TYPES from './title.types';

export const TITLE_INITIAL_STATE = {
  titleLists: [],
};

export const TitleReducer = (state = TITLE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TITLE_ACTION_TYPES.LOAD_TITLES: {
      return { ...state, titleLists: payload };
    }
    default:
      return state;
  }
};
