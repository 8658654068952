export const title = 'إمام';
export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const baseUrl = "https://imam.tech-and-beyond.org";
export const fileUrl = baseUrl+"/uploads/";
export const settingId = "664b3f47e83b37036b4a94bb";
export const apiVersion1 = '';
export const avatarImage = '/assets/images/empty-avatar.png'
    