import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const TitleList = Loadable(lazy(() => import('app/views/titles/Title')));
const TitleCreate = Loadable(lazy(() => import('app/views/titles/TitleCreate')));
const TitleUpdate = Loadable(lazy(() => import('app/views/titles/TitleUpdate')));

const titleRoutes = [
  {
    path: '/titles',
    element: <TitleList />,
    auth: authRoles.admin
  },
  {
    path: '/titles/create',
    element: <TitleCreate />,
    auth: authRoles.admin
  },
  {
    path: '/titles/edit/:titleId',
    element: <TitleUpdate />,
    auth: authRoles.admin
  }
];

export default titleRoutes;

