import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const ScreenList = Loadable(lazy(() => import('app/views/screens/Screen')));
const ScreenCreate = Loadable(lazy(() => import('app/views/screens/ScreenCreate')));
const ScreenUpdate = Loadable(lazy(() => import('app/views/screens/ScreenUpdate')));

const screenRoutes = [
  {
    path: '/screens',
    element: <ScreenList />,
    auth: authRoles.admin
  },
  {
    path: '/screens/create',
    element: <ScreenCreate />,
    auth: authRoles.admin
  },
  {
    path: '/screens/edit/:screenId',
    element: <ScreenUpdate />,
    auth: authRoles.admin
  }
];

export default screenRoutes;

